import logo from './Portrait.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} style={{radius: '6%', marginTop: '2%', border: '3px solid white'}} className="App-logo" alt="logo" />
        <div style={{margin: '2%'}}><p>
          Biography: Passionate about building on the internet, education and relationships.
        </p></div>
        <h3>Hi there, i'm <a href="https://www.linkedin.com/in/cristionbrown" target="_BLANK" style={{color: 'white'}}>Cristion</a>. It's nice to e-meet ya ;)</h3>
        <div style={{margin: '4%'}}>
      

<h3>Early Life</h3>

- NC State University Engineering Research Program <br />
- Duke University Biosciences and Engineering Research Program <br />
- UPenn Biomedical Research Program <br />
- UVA Computer Science Research Program <br />
- Northwestern Bioscience Research Program <br />
- <a href="https://www.fcnp.com/2013/07/09/mason-student-to-study-biomedicine-at-upenn/" target="_BLANK" style={{color: 'white'}}>George Mason High School Graduate</a> <br />

<h3>Higher Education</h3>

- Miami University (B.S. in Software Engineering) <br />
Miami University MiniMBA <br />
National Society of Black Engineers <br />
Miami University Blockchain Club <br />


<h3>Career</h3>

- Adjunct Professor @ Cincinnati State<br />
    Web Application and Programming Topics<br />
    Directed learning of LAMP Stack & Accelerated SDLC's<br />
    Facilitated collaborative activities with dominant startups from Cincinnati and California<br /><br />
- Software Engineer @ Hypernova Technologies, Encova Insurance<br />
Certification: InsuranceSuite Developer Fundamentals<br /><br />
    - <a href="https://www.bizjournals.com/cincinnati/inno/stories/awards/2022/09/09/cincy-inno-5-under-25.html" target="_BLANK" style={{color: 'white'}}>Cincy Inno 5 Under 25</a>
    


        </div>

      </header>
    </div>
  );
}

export default App;
